<template>
  <div id="app">
    <!-- 资质证照要新开页面，并且不要头部和底部 -->
    <template v-if="$route.meta.qualify">
      <Head />
      <router-view />
    </template>
    <template v-else>
      <Head />
      <router-view />
      <Bottom />
    </template>
  </div>
</template>

<script>
import Head from './components/layout/head.vue'
import Bottom from './components/layout/bottom.vue'
import store from './store/index'
export default {
  components: {
    Head,
    Bottom
  },
  data() {
    return {}
  },
  watch: {
    offsetWidth(val, oldVal) {
      console.log(23)
      if (val !== oldVal) {
        location.reload()
      }
    }
  },
  methods: {
    setClient() {
      const screenWidth = document.body.offsetWidth
      if (screenWidth < 980) {
        store.dispatch('toggleScreen', false)
      } else {
        store.dispatch('toggleScreen', true)
      }
    }
  },
  mounted() {
    this.setClient()
  }
}
// This starter template is using Vue 3 experimental <script setup> SFCs
// Check out https://github.com/vuejs/rfcs/blob/master/active-rfcs/0040-script-setup.md
</script>
<style lang="less">
#app {
  width: 100%;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html, body {
  min-height: 100%;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background-color: #F6F6F6;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p, ul {
  margin: 0;
  padding: 0;
}

</style>
