<template>
  <div class="saveBottom">
    <ul class="save">
      <li class="save-item" v-for="(item, index) in saveList" :key="index">
        <img :src="item.image" alt="item.name" />
        <span>{{ item.name }}</span>
      </li>
    </ul>
    <div class="info" v-if="isMaxScreen">
      <div class="infos">
        <div class="phone-info">
          <div class="info-text">
            <span class="top">Registered Office Address:</span>
            <span class="phone">
              {{ address }}
            </span>
          </div>
        </div>
        <div class="infos-bottom">
          <div class="phone-info">
            <div class="info-text">
              <p>
                <span>CIN:</span>
              </p>
              <span class="phone">{{ cin }}</span>
            </div>
          </div>
          <div class="phone-info">
            <div class="info-text">
              <p>
                <span>Support：</span>
              </p>
              <div class="phone">{{ phone }} <span class="orderTime" style="color: #999;">{{ orderTime }}</span></div>
              <div class="phone support-text">
                <div>{{ supportText }}</div>
                <div class="emil">{{ emil }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="corporate-info" @click="goInfoPage">Corporate Information</div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-else class="info">
      <div class="infos">
        <div class="infos-contact">Contact us</div>
        <div class="phone-info">
          <div class="address info-text">
            <div class="top">Registered Office Address:</div>
            <div class="phone">
              {{ address }}
            </div>
          </div>
        </div>
        <div class="phone-info">
          <div class="info-text">
            <p>
              <span class="top">CIN:</span>
            </p>
            <span class="phone">{{ cin }}</span>
          </div>
        </div>
        <div class="phone-info">
          <div class="info-text">
            <p>
              <span class="top">Support：</span>
            </p>
            <div>
              <div class="phone">{{ phone }}</div>
              <div style="color: #999;">{{ orderTime }}</div>
            </div>
          </div>
          <div class="phone support">
            {{ supportText }}
            <span class="emil">{{ emil }}</span>
          </div>
        </div>
        <div class="corporate-info1" @click="goInfoPage">Corporate Information</div>
      </div>
    </div>
    <div class="record">{{ record }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      saveList: [
        {
          image: require('../../assets/image/saveBottom/icon_anquan.png'),
          name: 'Safety '
        },
        {
          image: require('../../assets/image/saveBottom/icon_bianjie.png'),
          name: 'Convenient'
        },
        {
          image: require('../../assets/image/saveBottom/icon_zhaunye.png'),
          name: 'Professional '
        }
      ],
      address:
        'Eswop Technology Private Limited, Basement and ground floor, B-13, sector-57, Gautam Buddha Nagar, Noida, Uttarpradesh, 201301, India',
      cin: 'U74999UP2021PTC148440',
      phone: '+91 9667942089',
      supportText:
        'Person who may be contacted in case of any compliance related queries or grievances：',
      emil: 'customersupport.instant@eswop.in',
      record: 'Copyright © 2024 Eswop Technology Private Limited.All Rights Reserved',
      orderTime: 'Mon-Sat 9.30am-6.pm (exc. Public Holiday)'
    };
  },
  computed: {
    ...mapState({
      isMaxScreen: state => {
        return state.isMaxScreen;
      }
    })
  },
  created() {
  },
  methods: {
    goInfoPage() {
      this.$router.push('Information')
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@media (max-width: 980px) {
  .saveBottom {
    background: #f2f5f8;
    .save {
      width: 100%;
      height: 100px;
      background: #272727;
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      .save-item {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 42px;
          height: 42px;
          margin-right: 8px;
        }
        span {
          color: white;
          font-size: 26px;
        }
      }
    }
    .info {
      padding: 0 24px;
      box-sizing: border-box;

      .infos {
        padding-top: 10px;
        box-sizing: border-box;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .infos-contact {
          display: flex;
          align-items: center;
          height: 80px;
          font-size: 28px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #333333;
          line-height: 42px;
        }
        .phone-info {
          width: 100%;
          text-align: left;
          box-sizing: border-box;
          padding: 24px 0;
          img {
            width: 24px;
            height: 24px;
            margin: 4px 14px 0 0;
          }
          .info-text {
            display: flex;
            //align-items: center;
            line-height: 40px;
            span {
              font-size: 24px;
              line-height: 24px;
              line-height: 36px;
            }
            .orderTime {
              font-size: 32px;
            }
            .hotService {
              font-size: 24px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #666666;
              line-height: 36px;
            }
            .top {
              height: 36px;
              line-height: 36px;
              margin: 0 16px 16px 0;
              font-size: 24px;
              color: #666666;
            }
            .address {
              color: #666;
              font-size: 24px;
              font-weight: normal;
            }
          }
          .phone {
            font-size: 24px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #111111;
            line-height: 36px;
          }
          .support {
            margin-top: 16px;
          }
          .service-text {
            height: 68px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .hotService {
              margin-right: 16px;
              font-size: 24px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #666666;
              line-height: 36px;
            }
            .button {
              height: 60px;
              padding: 0 25px;
              background: #ff9600;
              border-radius: 40px;
              display: flex;
              align-items: center;
              font-size: 24px;
              img {
                width: 28px;
                height: 28px;
                margin: 0 6px 0 0;
              }
              span {
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #ffffff;
                margin: 0;
              }
            }
          }
        }
        .address {
          display: block !important;
        }
      }
    }
    .record {
      width: 100%;
      padding: 40px;
      box-sizing: border-box;
      color: #999;
    }
    @supports (bottom: env(safe-area-inset-bottom)) or (bottom: constant(safe-area-inset-bottom)) {
      .record {
        margin-bottom: env(safe-area-inset-bottom);
        margin-bottom: constant(safe-area-inset-bottom);
      }
    }
  }
}
@media (min-width: 981px) {
  .saveBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    .save {
      width: 100%;
      height: 180px;
      background: #272727;
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      .save-item {
        width: 300px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 42px;
          height: 42px;
          margin-right: 8px;
        }
        span {
          color: white;
          font-size: 26px;
        }
      }
    }
    .info {
      width: 1200px;
      padding: 37px 0;
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      .infos {
        width: 100%;
        box-sizing: border-box;
        .infos-bottom {
          display: flex;
          margin-top: 36px;
        }

        .phone-info {
          display: flex;
          align-items: flex-start;
          margin-right: 60px;
          &:last-child {
            margin-right: 0;
          }
          .info-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            span {
              font-size: 16px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #777777;
              line-height: 24px;
            }
            .phone {
              margin-top: 16px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
            }
            .support-text{
              margin-top: 8px;
            }
          }
        }

        .button-wrappr {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex: 1;
          .button {
            height: 60px;
            color: white;
            background: #ff9600;
            border-radius: 35px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 22px;
            padding: 0 35px;
            img {
              width: 40px;
              height: 40px;
              margin-right: 7px;
            }
          }
        }
      }
    }
    .record {
      width: 1200px;
      padding: 24px 0;
      margin: 0 auto;
      font-size: 12px;
      color: #999;
    }
  }
}
.emil {
  color: #3b89e0 !important;
  text-align: left;
}

.corporate-info {
  width: 180px;
  text-align: left;
  color: #777;
  cursor: pointer;
}

.corporate-info:hover {
  text-align: left;
  color: #3b89e0 !important;
  cursor: pointer;
  text-decoration: underline;
}

.corporate-info1 {
  width: 360px;
  text-align: left;
  color: #777;
  cursor: pointer;
  padding-bottom: 24px;
}
</style>
