
<template>
  <div>
    <div class="carousel" id="swiperOne" @mouseenter="enter" @mouseleave="leave" v-show="isMaxScreen">
      <swiper :options="swiperOption" class="carousel-list swiper-no-swiping" ref="mySwiper">
        <swiper-slide class="carousel-item" v-for="(item, index) in bannerList" :key="index">
          <img :src="item.image" alt="banner" @click="toPage(item.url, item.sendSensorsName)">
        </swiper-slide>
        <div class="swiper-pagination"  slot="pagination" v-show="false"></div>
        <div class="swiper-button-prev" slot="button-prev" @click="slidePrev" v-show="isShow">
          <img src="@/assets/image/home/icon_zuojiantou@2x.png" alt="">
        </div>
        <div class="swiper-button-next" slot="button-next" @click="slideNext" v-show="isShow">
          <img src="@/assets/image/home/icon_youjiantou@2x.png" alt="">
        </div>
      </swiper>
    </div>
    <!-- 移动端 -->
    <div class="carousels" id="swiperTwo" v-show="!isMaxScreen">
      <swiper :options="swiperOption" class="carousel-lists swiper-no-swiping">
        <swiper-slide class="carousel-items" v-for="(item, index) in bannerList1" :key="index">
          <img :src="item.image" alt="banner" @click="toPage(item.url, item.sendSensorsName)">
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" v-show="false"></div>
        <div class="swiper-button-prev" slot="button-prev" @click="slidePrev" v-show="false">
          <img src="@/assets/image/home/icon_zuojiantou@2x.png" alt="">
        </div>
        <div class="swiper-button-next" slot="button-next" @click="slideNext" v-show="false">
          <img src="@/assets/image/home/icon_youjiantou@2x.png" alt="">
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      bannerList: [
        {
          image: require('@/assets/image/home/banner_pc_realme.png'),
          url: 'https://buy.realme.com/in/goods/721',
          sendSensorsName: 'dhgwxdhsbanner'
        }
      ],
      bannerList1: [
        {
          image: require('@/assets/image/home/banner_mobile_realme@3x.png'),
          url: 'https://buy.realme.com/in/goods/721',
          sendSensorsName: 'dhgwxdhsbanner'
        }
      ],
      swiperOption: {
        speed: 600,
        loop: false,
        observer: true,
        onlyExternal: true,
        autoplayDisableOnInteraction: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletElement: 'li'
        },
        allowSlideNext: true,
        allowSlidePrev: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      isShow: false
    }
  },
  computed: {
    ...mapState({
      isMaxScreen: state => {
        return state.isMaxScreen
      }
    }),
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {
    slidePrev () {
      this.$refs.mySwiper.swiper.slidePrev()
    },
    slideNext () {
      this.$refs.mySwiper.swiper.slideNext()
    },
    // 鼠标移入
    enter () {    },
    leave () {    },
    // 跳转
    toPage (url, name) {
      if(!url) return
      window.open(url)
    }
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@media (max-width: 980px) {
  .carousels {
    width: 100%;
    height: 280px;
    border-radius: 20px!important;
    overflow: hidden;
    cursor: pointer;
    .carousel-lists {
      width: 100%!important;
      height: 100%;
      border-radius: 20px;
      overflow: hidden!important;
      .carousel-items {
        width: 100%!important;
        height: 280px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 24px;
        }
      }
    }
  }
}
@media (min-width: 981px) {
  .carousel {
    width: 100%;
    height: 440;
    overflow: hidden;
    cursor: pointer;
    .carousel-list {
      width: 100%;
      height: 100%;
      position: relative;
      .carousel-item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
      }
      .swiper-pagination {
        width: 100%;
        height: 10;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        list-style: none;
        z-index: 9999!important;
        position: absolute;
        bottom: 24;
      }
      /deep/.swiper-pagination-bullet{
        width: 30;
        height: 4;
        border-radius: 2;
        opacity: 0.4;
        margin-right: 12;
        background: #FFF!important;
      }
      /deep/.swiper-pagination-bullet-active {
        opacity: 1;
      }
      .swiper-button-prev, .swiper-button-next {
        width: 72;
        height: 72;
        z-index: 9999!important;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .swiper-button-prev {
        position: absolute;
        top: 50%;
        left: 220;
        transform: translate(-36, -36);
      }
      .swiper-button-next {
        position: absolute;
        top: 50%;
        right: 220;
        transform: translate(-36, -36);
      }
    }
  }
}
</style>
