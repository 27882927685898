<template>
  <div class="info-corporate">
    <p class="title">Corporate Information</p>
    <h1><a href="https://www.eswop.in/agreement/Annual%20Return-2022-23.pdf" download="Annual%20Return-2021-22.pdf">Annual Return-2022-23</a></h1>
    <h1><a href="https://www.eswop.in/agreement/Annual%20Return-2021-22.pdf" download="Annual%20Return-2021-22.pdf">Annual Return-2021-22</a></h1>
  </div>
</template>

<script>
export default {
  name: 'Information'
}
</script>

<style lang="less" scoped>
.info-corporate {
  .title {
    padding: 15px 0;
    line-height: 50px;
    font-size: 50px;
    font-weight: 700;
  }

  a {
    color: #3b89e0 !important;
    text-decoration: underline;
  }

  //a:hover {
  //  color: #3b89e0 !important;
  //  text-decoration: underline;
  //}
}
</style>
